import { FC } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { IAnswerResponse } from '../../../../../api/assessorApi/assessorApi.types';
import { CheckedIcon } from '../../../../components/icons/checked';
import { ChevronsIcon } from '../../../../components/icons/chevrons';
import { CloseIcon } from '../../../../components/icons/close';
import { FlameIcon } from '../../../../components/icons/flame-icon';
import { AnswerContent } from './AnswerContent';

interface AnswerItemProps {
  isActive?: boolean;
  answer: IAnswerResponse;
  onChangeActive?: () => void;
}

const STATUSES = {
  new: {
    label: 'Новое',
    icon: <FlameIcon />,
  },
  finished: {
    label: 'Завершено',
    icon: <CheckedIcon />,
  },
  empty: {
    label: 'Нет ответа',
    icon: <CloseIcon color="red" />,
  },
  not_checked: {
    label: 'Не требует проверки',
    icon: <CloseIcon />,
  },
};

export const AnswerItem: FC<AnswerItemProps> = ({ answer, isActive, onChangeActive }) => (
  <div onClick={onChangeActive} className="rounded-lg border-solid border-2 px-5 py-4 shadow-sm cursor-pointer">
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <span className="font-medium">Открытый вопрос</span>
        <span className="font-normal ml-2 text-slate-500">от {dayjs(answer.created_at).format('DD MMMM YYYY')}</span>
        <div className="w-px h-4 bg-slate-300 mx-3" />
        <div className="flex items-center">
          {STATUSES[answer.status]?.icon}
          <span className="ml-1 text-sm">{STATUSES[answer.status]?.label}</span>
        </div>
      </div>
      <ChevronsIcon className={classNames('w-6 h-6 bg-slate-200 text-slate-400 rounded-md', { 'rotate-180': isActive })} />
    </div>

    <AnswerContent isActive={isActive} answer={answer} />

  </div>
);
