/* eslint-disable import/no-unused-modules */
export interface IAnswerParams {
  competency?: string
  only_new?: boolean
  paginate_data: PaginateData
  publish_date?: PublishDate
}

export enum GradeLevel {
  basic_level = 'basic_level',
  normal_level = 'normal_level',
  advanced_level = 'advanced_level',
  expert_level = 'expert_level',
}

export enum GradeLevelRu {
  basic_level = 'Минимально-исходный',
  normal_level = 'Базовый уровень',
  advanced_level = 'Продвинутый уровень',
  expert_level = 'Экспертный уровень',
}

export interface PaginateData {
  limit: number
  offset?: number
  page: number
}

export interface PublishDate {
  day: number
  month: number
  year: number
}


export interface IAnswerResponse {
  _id: string
  question_id: string
  question: string
  pictures: string[] | null
  final_grade?: AssessorGrade;
  grades: AssessorGrade[]
  assessor_hint: string
  user_answer: string
  status: 'new' | 'finished' | 'not_checked' | 'empty';
  major_comp: Comp
  comps: Comp[]
  created_at: string
  finished_at: string
  publish_date: PublishDate
}


export interface Comp {
  'comp_id': string
  'comp_title': string
  'comp_grade'?: GradeLevel
}

export interface IApproveAnswerParams {
  _id: string
  comps: Competency[]
  major_comp: Competency
}

export interface AssessorGrade extends Omit<IApproveAnswerParams, '_id'> {
  assessor_id: string;
}

export interface Competency {
  'comp_grade': GradeLevel
  'comp_id': string
  'comp_title': string
}
