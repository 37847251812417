export enum EnvKeys {
  assessorApi = 'REACT_APP_ASSESSOR_API',
  builderAPI = 'REACT_APP_ASSMNT_BUILDERNEXT_API',
  yandexMetrikaId = 'REACT_APP_ASSMNT_YANDEX_METRIKA_ID',
  localToken = 'REACT_APP_LOCAL_TOKEN',
  localS3Token = 'REACT_APP_LOCAL_S3_TOKEN',
  bucketAPI = 'REACT_APP_ASSMNT_S3_BUCKET_API',
}

export interface IConfigStore {
  builderAPI: string;
  assessorApi: string;
  localToken: string | undefined;
  localS3Token: string | undefined;
  yandexMetrikaId: string | undefined;
  bucketAPI: string;
}
